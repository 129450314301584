html, 
body,
#root {
  height: 100%;
}

.MuiOutlinedInput-input.Mui-disabled {
  background-color: rgb(177 177 177 / 38%);
}

.icon-lock {
  display: inline;
  cursor: pointer;
  position: absolute;
  margin-left: -35px;
  margin-top: 12px;
}

.MuiTableCell-body, .MuiTableCell-head {
  font-size: 14px !important;
}
.MuiTableCell-body a, .MuiTableCell-body button {
  cursor: pointer;
}
.MuiButton-containedSuccess, .MuiButton-containedSuccess:hover {
  background-color: #2e7d32;
  color: white;
}
.MuiTypography-h5, .MuiListItemText-root p {
  font-size: 0.875rem !important;
  font-family: 'Roboto',sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.334 !important;
}
.MuiTableCell-body button {
  background-color: transparent;
  border-width: 0;
}

.document-button button {
  background-color: #2196f3 !important;
}

.cs-avatar img {
  object-fit: cover;
}

.fullheight {
  height: 100%;
}
/* input[type="date"]:before {
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  position: absolute;
  background: #fafafa;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
} */

.icon-section {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 600;
}

.loading-btn.Mui-disabled {
  background-color: #d5d5d5;
  color: white;
}